import * as request from '@/utils/request'

export default {
    getChapterList(data) {
        return request.post('/chapter/index', data)
    },

    storeChapterList(data) {
        return request.post('/chapter/store', data)
    },

    deleteChapter(data) {
        return request.post('/chapter/delete', data)
    },

    getChapterInfo(data) {
        return request.post('/chapter/info', data)
    },

    orderChapterList(data) {
        return request.post('/chapter/order', data)
    },

    getChapterQuestionPool(data) {
        return request.post('/chapter_question/pool', data)
    },

    storeChapterQuestion(data) {
        return request.post('/chapter_question/store', data)
    },

    getChapterQuestionList(data) {
        return request.post('/chapter_question/index', data)
    },

    upDateChapterQuestion(data){
        return request.post('/chapter_question/update', data)
    },

    summaryChapterQuestion(data){
        return request.post('/chapter_question/summary', data)
    },

    deleteChapterQuestion(data){
        return request.post('/chapter_question/delete', data)
    },

    deleteWhereChapterQuestion(data){
        return request.post('/chapter_question/delete_where', data)
    },
}