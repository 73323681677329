import * as request from '@/utils/request'

export default {
    // 获取纠错列表
    queryCorrectionList(data) {
        return request.post('/user/error_correction/index', data)
    },
    // 纠错->错误类型
    queryCorrectionStatus(data) {
        return request.post('/common/dict', data)
    },

    // getQuestionBankQuestionInfo(id) {
    //     let data = {id: id};
    //     return request.post('question/bank/question/info', data)
    // },

    handleUserErrorCorrection(data) {
        return request.post('/user/error_correction/handle', data)
    },
}