import * as request from '@/utils/request'

export default {
    getPromotionList(data) {
        return request.post('/promotion/index', data)
    },

    storePromotionInfo(data) {
        return request.post('/promotion/store', data)
    },

    getPromotionInfo(id) {
        return request.post('/promotion/info', {id})
    },

    deletePromotionInfo(data) {
        return request.post('/promotion/delete', data)
    },

}