import * as request from "@/utils/request";

export default {
    getUnitMemberList(data) {
        return request.post('/unit_member/index', data)
    },

    saveUnitMember(data) {
        return request.post('/unit_member/store', data)
    },

    getUnitMember(data) {
        return request.post('/unit_member/info', data)
    },

    deleteUnitMember(data) {
        return request.post('/unit_member/delete', data)
    },
}