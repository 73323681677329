import * as request from '@/utils/request'

export default {
    getFeedbackList(data) {
        return request.post('/user/feedback/index', data)
    },
    getFeedbackInfo(data) {
        return request.post('/user/feedback/info', data)
    },
    replyFeedback(data) {
        return request.post('user/feedback/reply', data)
    },
    updateFeedback(data) {
        return request.post('/user/feedback/update', data)
    },
}