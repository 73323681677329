import * as request from '@/utils/request'

export default {
    getOrderList(data) {
        return request.post('/user_order/index',data)
    },

    getOrderInfo(data) {
        return request.post('/user_order/info',data)
    },
}