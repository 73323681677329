import * as request from '@/utils/request'

export default {

    storeSpecialTopic(data) {
        return request.post('/special_topic/store', data)
    },

    getSpecialTopicList(data) {
        return request.post('/special_topic/index', data)
    },

    orderSpecialTopic(data) {
        return request.post('/special_topic/order', data)
    },

    getSpecialTopicInfo(data) {
        return request.post('/special_topic/info', data)
    },

    deleteSpecialTopic(data) {
        return request.post('/special_topic/delete', data)
    },

    deleteSpecialCategoryTopic(data) {
        return request.post('/special_topic/category/delete', data)
    },

    storeSpecialTopicCategory(data) {
        return request.post('/special_topic/category/store', data)
    },

    getSpecialTopicCategoryList(data) {
        return request.post('/special_topic/category/index', data)
    },

    orderSpecialTopicCategory(data) {
        return request.post('/special_topic/category/order', data)
    },

    getSpecialTopicCategoryInfo(data) {
        return request.post('/special_topic/category/info', data)
    },

    getSpecialTopicCategoryPool(data) {
        return request.post('/special_topic/category/pool', data)
    },

    selectSpecialTopicCategoryArticle(data) {
        return request.post('/special_topic/category/select', data)
    },

    selectSpecialTopicCategoryResourceList(data) {
        return request.post('/special_topic/category/resource_list', data)
    },

    deleteSpecialTopicResourceList(data) {
        return request.post('special_topic/category/resource/delete', data)
    },

    orderSpecialTopicResourceList(data) {
        return request.post('special_topic/category/resource/order', data)
    },

}