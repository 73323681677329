export default {
    path: 'history_real',
    component: () => import(/* webpackChunkName: "history-real" */ '@/pages/history_real/Index'),
    meta: {title:'历史真题'},
    children: [
        {
            path: ':c_id/:qb_id/create',
            component: () => import(/* webpackChunkName: "question-type-create" */ '@/pages/history_real/CreateEdit'),
            meta: {title:'历史真题选题'},
        },
        {
            path: ':type_id/edit',
            component: () => import(/* webpackChunkName: "question-type-edit" */ '@/pages/history_real/CreateEdit'),
            meta: {title:'编辑题型'},
        }
    ]
}