import * as request from '@/utils/request'

export default {
    getStudyGuideList(data) {
        return request.post('/study_guide/index', data)
    },

    orderStudyGuide(data) {
        return request.post('/study_guide/order', data)
    },

    getStudyGuideInfo(data){
        return request.post('study_guide/info', data)
    },

    deleteStudyGuideInfo(data){
        return request.post('study_guide/delete', data)
    },

    storeStudyGuide(data){
        return request.post('/study_guide/store', data)
    },

    poolStudyGuide(data){
        return request.post('/study_guide/pool', data)
    },

    selectStudyGuideArticle(data){
        return request.post('/study_guide/select', data)
    },

    selectStudyGuideResourceList(data){
        return request.post('/study_guide/resource_list', data)
    },

    deleteStudyGuideResourceList(data){
        return request.post('study_guide/resource/delete', data)
    },

    orderStudyGuideResourceList(data){
        return request.post('study_guide/resource/order', data)
    },

}