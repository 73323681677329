export default {
    path: 'chapter',
    component: () => import(/* webpackChunkName: "question-type" */ '@/pages/chapter/Index'),
    meta: {title:'章节练习'},
    children: [
        {
            path: ':c_id/create',
            component: () => import(/* webpackChunkName: "question-type-create" */ '@/pages/chapter/CreateEdit'),
            meta: {title:'章节选题'},
        },
        {
            path: ':type_id/edit',
            component: () => import(/* webpackChunkName: "question-type-edit" */ '@/pages/chapter/CreateEdit'),
            meta: {title:'编辑章节'},
        }
    ]
}