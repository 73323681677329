export default {
    path: 'special_topic',
    component: () => import(/* webpackChunkName: "special-topic" */ '@/pages/special_topic/Index'),
    meta: {title: '专题列表'},
    children: [
        {
            path: 'create',
            component: () => import(/* webpackChunkName: "special-topic-create" */ '@/pages/special_topic/CreateEdit'),
            meta: {title: '新增专题'},
        },
        {
            path: ':s_id/edit',
            component: () => import(/* webpackChunkName: "special-topic-edit" */ '@/pages/special_topic/CreateEdit'),
            meta: {title: '编辑专题'},
        },
        {
            path: ':s_id/category',
            component: () => import(/* webpackChunkName: "special-topic-category" */ '@/pages/special_topic/category/Index'),
            meta: {title: '专题分类'},
            children: [
                {
                    path: ':c_id/select',
                    component: () => import(/* webpackChunkName: "special-topic-category-select" */ '@/pages/special_topic/category/CreateEdit'),
                    meta: {title: '选择文章'},
                },
            ]
        }
    ]
}