import * as request from '@/utils/request'

export default {
    getDailyList(data) {
        return request.post('/daily/index', data)
    },

    storeDaily(data){
        return request.post('/daily/store', data)
    },

    getDailyInfo(data){
        return request.post('/daily/info', data)
    },

    deleteDailyInfo(data){
        return request.post('/daily/delete', data)
    },

    getDailyQuestionPool(data) {
        return request.post('/daily_question/pool', data)
    },

    storeDailyQuestion(data) {
        return request.post('/daily_question/store', data)
    },

    getDailyQuestionList(data) {
        return request.post('/daily_question/index', data)
    },

    upDateDailyQuestion(data){
        return request.post('/daily_question/update', data)
    },

    summaryDailyQuestion(data){
        return request.post('/daily_question/summary', data)
    },

    deleteDailyQuestion(data){
        return request.post('/daily_question/delete', data)
    },


    getDailyResourcePool(data) {
        return request.post('/daily_resource/pool', data)
    },

    storeDailyResource(data) {
        return request.post('/daily_resource/store', data)
    },

    getDailyResourceList(data) {
        return request.post('/daily_resource/index', data)
    },


    cloneDailyResource(data){
        return request.post('/daily/clone', data)
    },

    deleteDailyResource(data){
        return request.post('/daily_resource/delete', data)
    }
}