// 跟级别的Getters
import state from "./state";

const getters = {
    menuList: state => state.menuList,
    isCollapse: state => state.isCollapse,
    routePath: state => state.routePath,
    userInfo: state => state.userInfo,
    userPermissions: state => state.userPermissions,
    respErrMsgBoxMark: state => state.responseErrorMessageBoxMark,
    buttonType: state => state.buttonType,
    loadingStatus: state => state.loadingStatus,
    questionBankInfo: state => state.questionBankInfo,
    autoId: state => state.autoId,
    examChooseBankList: state => {
        if (! state.examChooseBankJson) {
            return []
        }
        return JSON.parse(state.examChooseBankJson)
    },
    userBankChoice: state => {
        if (state.userBankChoice.bank_id) {
            return state.userBankChoice;
        }

        let json = localStorage.getItem(state.localBankChoiceKey);
        return JSON.parse(json);
    },
    curChooseBankId: state => {
        console.log('state.userBankChoice.bank_id',state.userBankChoice.bank_id);

        if (state.userBankChoice && state.userBankChoice.bank_id) {
            return state.userBankChoice.bank_id;
        }

        let json = localStorage.getItem(state.localBankChoiceKey);
        if (json == null || json.length === 0) {
            return "";
        }
        let choice = JSON.parse(json);
        return choice.bank_id;
    },

    curChooseBankName: state => {
        if (state.userBankChoice != null && state.userBankChoice.name) {
            return state.userBankChoice.name;
        }

        let json = localStorage.getItem(state.localBankChoiceKey);
        console.log("key:", state.localBankChoiceKey)
        console.log("json:", json)
        if (json == null || json.length === 0) {
            return "";
        }
        let choice = JSON.parse(json);
        return choice.name;
    },

    localBankChoiceKey: state => state.localBankChoiceKey,
}

export default getters