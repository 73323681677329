import * as request from '@/utils/request'

export default {
    getQuestionTypeList(data) {
        return request.post('/question_type/index', data)
    },

    storeQuestionTypeList(data) {
        return request.post('/question_type/store', data)
    },

    deleteQuestionType(data) {
        return request.post('/question_type/delete', data)
    },

    getQuestionTypeInfo(data) {
        return request.post('/question_type/info', data)
    },

    orderQuestionTypeList(data) {
        return request.post('/question_type/order', data)
    },

    getQuestionTypeQuestionPool(data) {
        return request.post('/question_type_question/pool', data)
    },

    storeQuestionTypeQuestion(data) {
        return request.post('/question_type_question/store', data)
    },

    getQuestionTypeQuestionList(data) {
        return request.post('/question_type_question/index', data)
    },

    upDateQuestionTypeQuestion(data){
        return request.post('/question_type_question/update', data)
    },

    summaryQuestionTypeQuestion(data){
        return request.post('/question_type_question/summary', data)
    },

    deleteQuestionTypeQuestion(data){
        return request.post('/question_type_question/delete', data)
    },

    deleteWhereQuestionTypeQuestion(data){
        return request.post('/question_type_question/delete_where', data)
    },

    cleanQuestionTypeQuestion(data){
        return request.post('/question_type_question/clean', data)
    }
}