export default {
    path: 'exam_guide_list',
    component: () => import(/* webpackChunkName: "exam-guide-list" */ '@/pages/exam_guide/Index'),
    meta: {title:'考试指南管理'},
    children: [
        {
            path: 'create',
            component: () => import(/* webpackChunkName: "exam-guide-create" */ '@/pages/exam_guide/CreateEdit'),
            meta: {title:'添加考试指南'},
        },
        {
            path: ':sg_id/select',
            component: () => import(/* webpackChunkName: "exam-guide-edit" */ '@/pages/exam_guide/CreateEdit'),
            meta: {title:'编辑考试指南'},
        }
    ]
}