import * as request from '@/utils/request'

export default {
    getQuestionBankList(data) {
        return request.post('/question/bank/index', data)
    },
    getQuestionBankInfo(id) {
        let data = {id: id};
        return request.post('/question/bank/info', data)
    },
    saveQuestionBank(data) {
        return request.post('/question/bank/store', data)
    },
    deleteQuestionBank(data) {
        return request.post('/question/bank/delete', data)
    },
    getQuestionBankQuestionList(data) {
        return request.post('/question/bank/question/index', data)
    },
    getQuestionBankQuestionInfo(id) {
        let data = {id: id};
        return request.post('question/bank/question/info', data)
    },
    updateQuestionBankQuestion(data) {
        return request.post('question/bank/question/update', data)
    },
    insertQuestionBankQuestion(data) {
        return request.post('question/bank/question/insert', data)
    },
    deleteQuestionBankQuestion(id) {
        let data = {id:id};
        return request.post('question/bank/question/delete', data)
    },
    getSelectQuestionList(data) {
        return request.post('/question/bank/question/select_list', data)
    },
    getQuestionBankSysList(data) {
        return request.post('/question/bank/sys_list', data)
    },
    getQuestionBankDefaultConfig(data) {
        return request.post('/question/bank/default_config', data)
    },

    chooseQuestionBank(data) {
        return request.post('question/bank/choose_bank', data)
    },

    getChoseQuestionBank(data){
        return request.post('question/bank/bank_choice', data)
    },

    shareQuestionBank(data){
        return request.post('question/bank/share', data)
    }

}