import * as request from '@/utils/request'

export default {
    getImportQuestionList(data) {
        return request.post('import/index', data)
    },
    importQuestion(data) {
        return request.post('import/questions', data)
    },

    storeImportQuestion(data) {
        return request.post('import/questions/store', data)
    },

    previewImportQuestion(file_id) {
        return request.post('import/questions/preview', {file_id})
    },

}