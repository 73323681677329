export default {
    path: 'unit_exam_list',
    component: () => import(/* webpackChunkName: "unit_exam" */ '@/pages/unit_exam/Index'),
    meta: {title: '单位考试'},

    children: [
        {
            path: ':g_id/:unit_id/create',
            component: () => import(/* webpackChunkName: "unit_exam" */ '@/pages/unit_exam/CreateExam'),
            meta: {title: '新增单位考试'},
        },
        {
            path: ':id/inspect',
            component: () => import(/* webpackChunkName: "unit_exam" */ '@/pages/unit_exam/Inspect'),
            meta: {title: '阅卷评分'},
        },
    ]
}