import * as request from '@/utils/request'

export default {
    getCustomerList(data) {
        return request.post('/customer/index', data)
    },

    storeCustomerInfo(data) {
        return request.post('/customer/store', data)
    },

    getCustomerInfo(data) {
        return request.post('/customer/info', data)
    },

    deleteCustomerInfo(data) {
        return request.post('/customer/delete', data)
    },

}