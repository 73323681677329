import * as request from '@/utils/request'

export default {
    getRealList(data) {
        return request.post('/real/index', data)
    },

    realToHome(data) {
        return request.post('/real/to_home', data)
    },

    storeRealList(data) {
        return request.post('/real/store', data)
    },

    deleteReal(id) {
        let data = {
            id: id
        };
        return request.post('/real/delete', data)
    },

    getRealInfo(id) {
        let data = {
            id: id
        };
        return request.post('/real/info', data)
    },

    orderRealList(data) {
        return request.post('/real/order', data)
    },

    getRealQuestionPool(data) {
        return request.post('/real_question/pool', data)
    },

    storeRealQuestion(data) {
        return request.post('/real_question/store', data)
    },

    getRealQuestionList(data) {
        return request.post('/real_question/index', data)
    },

    upDateRealQuestion(data) {
        return request.post('/real_question/update', data)
    },

    summaryRealQuestion(data) {
        return request.post('/real_question/summary', data)
    },

    deleteRealQuestion(data) {
        return request.post('/real_question/delete', data)
    },

    deleteWhereRealQuestion(data){
        return request.post('/real_question/delete_where', data)
    }
}