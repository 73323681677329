export default {
    path: 'test_paper',
    component: () => import(/* webpackChunkName: "test-paper" */ '@/pages/test_paper/Index'),
    meta: {title:'模拟试卷'},
    children: [
        {
            path: ':c_id/:qb_id/create',
            component: () => import(/* webpackChunkName: "test-paper-create" */ '@/pages/test_paper/CreateEdit'),
            meta: {title:'试卷选题'},
        },
        {
            path: ':type_id/edit',
            component: () => import(/* webpackChunkName: "test-paper-edit" */ '@/pages/test_paper/CreateEdit'),
            meta: {title:'编辑试卷'},
        }
    ]
}