import * as request from "@/utils/request";

export default {
    getUnitList(data) {
        return request.post('/unit/index', data)
    },

    saveUnit(data) {
        return request.post('/unit/store', data)
    },

    getUnit(id) {
        let data = {id: id}
        return request.post('/unit/info', data)
    },

    deleteUnit(id) {
        let data = {id: id}
        return request.post('/unit/delete', data)
    },

    getUnitShareList(id) {
        let data = {id: id}
        return request.post('/unit/share_list', data)
    },

    getCurUnit() {
        return request.post('/unit/cur_unit', {})
    },

    changeUnitExpired(data) {
        return request.post('/unit/expired/change', data)
    }
}
