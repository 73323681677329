import * as request from "@/utils/request";

export default {
    getUnitExamList(data) {
        return request.post('/unit_exam/index', data)
    },

    startUnitExamGenerate() {
        return request.post('/unit_exam/start_generate', {})
    },

    getUnitExamBankList() {
        return request.post('/unit_exam/bank_list', {})
    },

    storeUnitExamBasicGenerate(g_id, basic_info) {
        //name
        // start_at
        // end_at
        // score
        // status 0 -1
        // exam_time 分钟
        // exam_user_id all/id,id2,id3
        // pass_score
        return request.post('/unit_exam/store_generate', {g_id, basic_info})
    },

    getGenerateInfo(g_id) {
        return request.post('/unit_exam/generate_info', {g_id})
    },

    storeUnitExamPaperGenerate(g_id, paper_info) {
        return request.post('/unit_exam/store_generate', {g_id, paper_info})
    },

    previewUnitExam(g_id) {
        return request.post('/unit_exam/preview', {g_id})
    },

    previewBriefUnitExam(g_id) {
        return request.post('/unit_exam/preview_brief', {g_id})
    },

    getPreviewQuestionUnitExam(data) {
        return request.post('/unit_exam/preview_question', data)
    },

    previewSubmitUnitExam(data) {
        return request.post('/unit_exam/preview_submit', data)
    },

    getUnitExamInfo(id) {
        return request.post('/unit_exam/info', {id: id})
    },

    deleteUnitExamInfo(id) {
        return request.post('/unit_exam/delete', {id: id})
    },

    getReadBriefUnitExamInfo(id) {
        return request.post('/unit_exam/read_brief', {exam_id: id})
    },

    getUserUnitExamList(data) {
        return request.post('/unit_exam/user_exam_list', data)
    },

    getUnitExamReadAnswerSheet(user_exam_id) {
        return request.post('/unit_exam/read_answer_sheet', {user_exam_id})
    },

    getUnitExamReadQuestion(user_exam_id, qbq_id) {
        return request.post('/unit_exam/read_question', {user_exam_id,  qbq_id})
    },

    commitReadUnitExamScore(user_exam_question_id, score) {
        return request.post('/unit_exam/read_score/commit', {user_exam_question_id,  score})
    },

    finishReadUnitExamScore(user_exam_id) {
        return request.post('/unit_exam/read_score/finish', {user_exam_id})
    },

    getUserUnitExamInfo(user_exam_id) {
        return request.post('/unit_exam/user_exam_info', {user_exam_id})
    },
}