export default {
    path: 'daily_practice',
    component: () => import(/* webpackChunkName: "daily-practice" */ '@/pages/daily_practice/Index'),
    meta: {title:'每日练习'},
    children: [
        {
            path: ':c_id/:qb_id/create',
            component: () => import(/* webpackChunkName: "daily-practice-create" */ '@/pages/daily_practice/CreateEdit'),
            meta: {title:'题型选题'},
        },
        {
            path: ':c_id/:qb_id/article/select',
            component: () => import(/* webpackChunkName: "daily-practice-article-select" */ '@/pages/daily_practice/ArticleSelect'),
            meta: {title:'选择文章'},
        }
    ]
}