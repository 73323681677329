import Vue from 'vue'
import Router from 'vue-router'
import $vuex from '@/store'
import login from './login'

import menu from './menu'
import permissions from './permissions'
import roles from './roles'
// import user from './user'
// import setting from './setting'
// import adPosition from './ad_position'
// import link from './link'
// import category from './category'
// import live from './live'
// import lecturer from './lecturer'
// import statistics from './statistics'
// import attachment from './attachment'
// import sysLog from './sys_log'
import article from './article'
import carousel from './carousel'

import questionBank from './question_bank'
import questionBankQuestion from './question_bank_question'
import questionType from './question_type'
import testPaper from './test_paper'
import historyReal from './history_real'
import specialTopic from './special_topic'
import user from './user'
import order from './order'
import packages from './package'
import chapter from './chapter'
import importFile from './import_file'
import feedback from './feedback'
import correction from './correction'
import customer from './customer'
import dailyPractice from './daily_practice'
import userManer from './user_manner'
import examGuide from './exam_guide'
import unitExam from './unit-exam'
import unit from './unit'
import member from './member'
import promotion from './promotion'

// 解决vue报错vue-router.esm.js
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => {
    })
};

Vue.use(Router);

// 路由详情
const routes = [
    {
        path: '/',
        component: () => import('@/layouts/BasicLayout'),
        meta: {title: '首页'},
        children: [
            questionBank,//题库
            questionBankQuestion,//问题
            questionType,//题型
            chapter,//专题
            carousel, // 轮播图
            article, // 文章管理
            testPaper,//试卷管理
            historyReal,//历史真题
            specialTopic,//专题管理
            user,//用户管理
            order,//订单管理
            packages,//套餐管理
            importFile,//导入
            menu, // 菜单
            permissions, // 权限
            roles, // 角色
            feedback,//问题反馈
            correction, //题目纠错
            customer, //客服
            dailyPractice,//每日练习
            userManer,//用户管理,
            examGuide,//考试指南管理
            unitExam,//单位考试
            unit,//单位管理
            member,//学员列表
            promotion, //活动
            // setting, // 基础设置
            // adPosition, // 广告位
            // link, // 友情链接
            // category, // 分类管理
            // live, // 直播管理
            // lecturer, // 讲师管理
            // statistics, // 统计管理
            // attachment, // 附件管理
            // sysLog, // 系统日志
            {
                path: 'exception/404',
                component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/exception/404')
            }
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/PassportLayout'),
        children: [
            login, // 登录
        ]
    },
    {
        path: "*",
        redirect: '/exception/404',
        // component: () => import('@/pages/exception/404')
    }
];

// 实例化
const router = new Router({
    base: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : '/',
    mode: 'history',
    routes
});

// 路由钩子
router.beforeEach((to, from, next) => {
    ifLogin(to, next);
    handelSiderMenu(to);
    next()
});

/**
 * @description 处理左侧菜单的选中
 * @author YM
 * @date 2019-01-10
 * @param {*} to 当前路由
 */
let handelSiderMenu = (to) => {
    $vuex.commit('handleRoutePath', to.path)
}

/**
 * @description 处理左侧菜单的选中
 * @author YM
 * @date 2019-01-10
 * @param {*} to 当前路由
 * @param {*} next 回调函数
 */
let ifLogin = (to, next) => {
    let userInfo = JSON.parse(localStorage.getItem('user_info'));
    // 判断是否是登录页
    let idx = to.path.indexOf('login') != -1
    // 没有userInfo 且 不是login 页 => 登录页
    if (!userInfo && !idx) {
        next('/login')
    }
    // 有userinfo 且 是login 页 => 首页
    if (idx && userInfo) {
        next('/')
    }
};

export default router
