export default {
    path: 'article_list',
    component: () => import(/* webpackChunkName: "article-list" */ '@/pages/article/Index'),
    meta: {title:'文章管理'},
    children: [
        {
            path: ':total/create',
            component: () => import(/* webpackChunkName: "article-create" */ '@/pages/article/CreateEdit'),
            meta: {title:'添加文章'},
        },
        {
            path: ':total/:article_id/edit',
            component: () => import(/* webpackChunkName: "article-edit" */ '@/pages/article/CreateEdit'),
            meta: {title:'编辑文章'},
        }
    ]
}