import passport from './passport'
import common from './common'
import statistics from './statistics'
import menu from './menu'
import permissions from './permissions'
import roles from './roles'
import user from './user'
import setting from './setting'
import carousel from './carousel'
import adPosition from './ad_position'
import link from './link'
import category from './category'
import article from './article'
import live from './live'
import lecturer from './lecturer'
import attachment from './attachment'
import sysLog from './sys_log'

import questionBank from './question_bank'
import questionType from './question_type'
import testPaper from './test_paper'
import historyRealPaper from './history_real_paper'
import specialTopic from './special_topic'
import order from './order'
import packages from './packages'
import chapter from './chapter'
import importFile from './import_file'
import feedback from './feedback'
import correction from './correction'
import businessType from './business_type'
import customer from './customer'
import dailyPractice from './daily_practice'
import userManner from './user_manner'
import examGuide from './exam_guide'
import unit from './unit'
import member from './member'
import unitExam from './unit_exam'
import promotion from './promotion'

export default {
    // 通行证
    ...passport,
    // 通用
    ...common,
    // 统计数据
    ...statistics,
    // 菜单
    ...menu,
    // 权限
    ...permissions,
    // 角色
    ...roles,
    // 用户
    ...user,
    // 基础配置
    ...setting,
    // 轮播图
    ...carousel,
    // 广告位
    ...adPosition,
    // 友情链接
    ...link,
    // 分类管理
    ...category,
    // 文章管理
    ...article,
    // 直播管理
    ...live,
    // 讲师管理
    ...lecturer,
    // 附件管理
    ...attachment,
    // 系统日志管理
    ...sysLog,
    //题型
    ...questionType,
    //题库
    ...questionBank,
    //模拟试卷
    ...testPaper,
    //历史真题
    ...historyRealPaper,
    //专题管理
    ...specialTopic,
    //订单管理
    ...order,
    //套餐
    ...packages,
    //章节练习
    ...chapter,
    //导入
    ...importFile,
    //反馈
    ...feedback,
    //纠错
    ...correction,
    //获取题型
    ...businessType,
    //客服列表
    ...customer,
    //每日练习
    ...dailyPractice,
    //系统用户管理
    ...userManner,
    //考试指南管理
    ...examGuide,
    //单位管理
    ...unit,
    //学员管理
    ...member,
    //单位考试
    ...unitExam,
    //活动管理
    ...promotion
}
