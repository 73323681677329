import * as request from '@/utils/request'

export default {
    storePackage(data) {
        return request.post('/package/store',data)
    },

    getPackageList(data) {
        return request.post('/package/index',data)
    },

    getPackageListInfo(data) {
        return request.post('/package/info',data)
    },

    orderPackageList(data) {
        return request.post('/package/order',data)
    },
}