import * as request from '@/utils/request'

export default {
    getPassportUserList(data) {
        return request.post('passport/user/index', data)
    },

    createUser(data) {
        return request.post('passport/user/create', data)
    },

    updateUserPwd(data) {
        return request.post('passport/user/update_pwd', data)
    },

    deleteMannerUser(data) {
        return request.post('passport/user/delete', data)
    },
}