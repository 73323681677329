export default {
    path: 'question_type',
    component: () => import(/* webpackChunkName: "question-type" */ '@/pages/question_type/Index'),
    meta: {title:'题型练习'},
    children: [
        {
            path: ':c_id/:qb_id/create',
            component: () => import(/* webpackChunkName: "question-type-create" */ '@/pages/question_type/CreateEdit'),
            meta: {title:'题型选题'},
        },
        {
            path: ':type_id/edit',
            component: () => import(/* webpackChunkName: "question-type-edit" */ '@/pages/question_type/CreateEdit'),
            meta: {title:'编辑题型'},
        }
    ]
}