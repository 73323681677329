import * as request from '@/utils/request'

export default {
    getTestPaperList(data) {
        return request.post('/test_paper/index', data)
    },

    storeTestPaperList(data) {
        return request.post('/test_paper/store', data)
    },


    deleteTestPaper(id) {
        let data = {
            id:id
        };
        return request.post('/test_paper/delete', data)
    },

    getTestPaperInfo(id) {
        let data = {
            id:id
        };
        return request.post('/test_paper/info', data)
    },

    orderTestPaperList(data) {
        return request.post('/test_paper/order', data)
    },

    getTestPaperQuestionPool(data) {
        return request.post('/test_paper_question/pool', data)
    },

    storeTestPaperQuestion(data) {
        return request.post('/test_paper_question/store', data)
    },

    getTestPaperQuestionList(data) {
        return request.post('/test_paper_question/index', data)
    },

    upDateTestPaperQuestion(data){
        return request.post('/test_paper_question/update', data)
    },

    summaryTestPaperQuestion(data){
        return request.post('/test_paper_question/summary', data)
    },

    deleteTestPaperQuestion(data){
        return request.post('/test_paper_question/delete', data)
    },

    deleteWhereTestPaperQuestion(data){
        return request.post('/test_paper_question/delete_where', data)
    }
}